<template>
  <v-form ref="form" v-model="valid" lazy-validation class="mt-6">
    <v-dialog
      transition="dialog-top-transition"
      v-model="dialog"
      :max-width="options.width"
      :style="{ zIndex: options.zIndex }"
      persistent
      @keydown.esc="cancel"
    >
      <v-card>
        <v-toolbar :color="options.color" flat dark class="mb-8">
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
        </v-toolbar>

        <v-card-text :class="'red--text'">
          <h3>{{ message2 }}</h3>
        </v-card-text>
        <v-card-text v-show="!!message">
          <h3>{{ message }}</h3>
        </v-card-text>
        <v-card-text>
          <v-textarea
            dense
            @focus="$event.target.select()"
            v-model="motif"
            :label="'Motif'"
            :rules="[(v) => !!v || 'Motif obligatoire']"
            v-if="is_motif"
          ></v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center">
          <v-btn v-if="!options.noconfirm" @click.native="cancel" text
            >Abandonner</v-btn
          >
          <v-btn @click.native="agree" text>Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-form>
</template>

<script>
export default {
  name: "ConfirmDlg",
  props: {
    is_motif: { type: Boolean, default: false },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      message2: null,
      title: null,
      motif: null,
      valid: true,
      options: {
        color: "grey darken-1",
        width: 600,
        zIndex: 200,
        noconfirm: false,
      },
    };
  },

  methods: {
    open(title, message, options, message2) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.message2 = message2;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree() {
      if (this.$refs.form.validate()) {
        this.resolve(true);
        this.dialog = false;
        this.$emit("motif", this.motif);
      }
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
